import type { ObjectMapping } from 'common/src/types/utils';

/* eslint-disable camelcase */
import h_a_ from './h_a_.svg'; // accorhotels.com
import h_ad from './h_ad.svg'; // agoda.com
import h_an from './h_an.svg'; // Novotel
import h_bc from './h_bc.svg'; // Booking.com
import h_cr from './h_cr.svg'; // Cleatrip
import d_ct from './d_ct.svg'; // Ctrip
import h_ez from './h_ez.svg'; // Easy To Book
import h_hc from './h_hc.svg'; // hotels.com
import h_hi from './h_hi.svg'; // Hilton
import h_hv from './h_hv.svg'; // HotelTravel.com
import h_hy from './h_hy.svg'; // Hyatt
import h_i2 from './h_i2.svg'; // Holiday Inn
import h_ic from './h_ic.svg'; // Intercontinental
import h_jl from './h_jl.svg'; // Jalan
import h_jp from './h_jp.svg'; // Japanican
import h_jt from './h_jt.svg'; // JTB Japan
import h_oi from './h_oi.svg'; // Oyorooms
import h_oy from './h_oy.svg'; // Oyorooms
import h_ri from './h_ri.svg'; // Rakuten Japan
import h_sa from './h_sa.svg'; // Asia Travel
import h_sd from './h_sd.svg'; // Starwood
import h_sg from './h_sg.svg'; // Shangri-La
import h_tj from './h_tj.svg'; // Taj Hotels
import h_tr from './h_tr.svg'; // TravelBook
import h_wo from './h_wo.svg'; // wotif.com
import h_xp from './h_xp.svg'; // Expedia

const logos: ObjectMapping = {
  h_a_,
  h_ad,
  h_an,
  h_bc,
  h_cr,
  d_ct,
  h_ez,
  h_hc,
  h_hi,
  h_hv,
  h_hy,
  h_i2,
  h_ic,
  h_jl,
  h_jp,
  h_jt,
  h_oi,
  h_oy,
  h_ri,
  h_sa,
  h_sd,
  h_sg,
  h_tj,
  h_tr,
  h_wo,
  h_xp,
};

export const partners = {
  h_a_: 'Accor',
  h_ad: 'Agoda',
  h_an: 'Novotel',
  h_bc: 'Booking.com',
  h_cr: 'Cleatrip',
  d_ct: 'Trip.com',
  h_ez: 'Easy To Book',
  h_hc: 'Hotels.com',
  h_hi: 'Hilton',
  h_hv: 'HotelTravel.com',
  h_hy: 'Hyatt',
  h_i2: 'Holiday Inn',
  h_ic: 'Intercontinental',
  h_jl: 'Jalan',
  h_jp: 'Japanican',
  h_jt: 'JTB Japan',
  h_oi: 'Oyorooms',
  h_oy: 'Oyorooms',
  h_ri: 'Rakuten Japan',
  h_sa: 'Asia Travel',
  h_sd: 'Starwood',
  h_sg: 'Shangri-La',
  h_tj: 'Taj Hotels',
  h_tr: 'TravelBook',
  h_wo: 'Wotif.com',
  h_xp: 'Expedia',
};

export default logos;
